<template>
	<div :class="['activity-item', className('activity-item-')]" @click="onClick" >
		<div class="activity-item-header">
			<div class="activity-item-title">{{ activity.title }}</div>
			<div class="activity-item-tags">
				<div class="activity-item-tag activity-item-tag-blue">{{onText(activity.activity_status)}}</div>	
				<div class="activity-item-tag activity-item-tag-yellow">{{activity.register_number}}/{{activity.people_number}}人</div>
				<div class="activity-item-tag activity-item-tag-green">{{activity.group_name}}</div>
			</div>
		</div>
		<div class="activity-item-body">
			<div class="activity-item-cell">
				<div class="activity-item-cell-title activity-item-cell-time">打卡时间</div>
				<div class="activity-item-cell-content">{{activity.clock_time}}</div>
			</div>
			<div class="activity-item-cell">
				<div class="activity-item-cell-title activity-item-cell-date">活动日期</div>
				<div class="activity-item-cell-content">{{activity.activity_time}}</div>
			</div>
			<div class="activity-item-cell">
				<div class="activity-item-cell-title activity-item-cell-address">活动地址</div>
				<div class="activity-item-cell-content">{{ activity.address }}</div>
			</div>
		</div>
		<div class="activity-item-footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['className'],
	props: {
		activity: {
			default: []
		}
	},
	methods: {
		onClick() {
			this.$emit('click', this.activity);
		},
		onText(status){
			let text ={1:'报名中',2:'活动中',3:'已结束'}
			return text[status]
		}
	}
};
</script>

<style lang="less" scoped>
@import '~@/assets/less/style.less';

.activity-item:last-child{
	margin-bottom: 0 !important;
}

.activity-item {
	background-color: #fff;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 6px;
	
	.activity-item-header {
		padding-bottom: 8px;

		.activity-item-title {
			font-size: 18px;
			padding-bottom: 8px;
		}

		.activity-item-tags {
			display: flex;

			.activity-item-tag {
				font-size: 14px;
				padding: 3px 8px;
				margin-right: 8px;
				border-radius: 6px;
			}

			.activity-item-tag-blue {
				background-color: rgba(51, 119, 255, 0.1);
				color: #3377ff;
			}

			.activity-item-tag-yellow {
				background-color: rgba(255, 178, 63, 0.1);
				color: #ffb23f;
			}

			.activity-item-tag-green {
				background-color: rgba(112, 201, 26, 0.1);
				color: #6abb1b;
			}
		}
	}

	.activity-item-body {
		.activity-item-cell {
			display: flex;
			justify-content: space-between;
			height: 26px;
			line-height: 26px;
			padding-top: 8px;

			&:first-child {
				padding-top: 0;
			}
		}

		.activity-item-cell-title {
			color: #666;
			font-size: 16px;
			padding-left: 21px;
			background-position: left center;
			background-repeat: no-repeat;
			background-size: 16px;
			min-width: 100px;
			box-sizing: border-box;
		}

		.activity-item-cell-content {
			color: #333;
			font-size: 16px;
			.text-overflow-1();
		}
	}
}

.activity-item-city {
	.activity-item-cell-time {
		background-image: url('~@/assets/img/icon10.png');
	}

	.activity-item-cell-date {
		background-image: url('~@/assets/img/icon11.png');
	}

	.activity-item-cell-address {
		background-image: url('~@/assets/img/icon12.png');
	}
}

.activity-item-district {
	.activity-item-cell-time {
		background-image: url('~@/assets/img/icon31.png');
	}

	.activity-item-cell-date {
		background-image: url('~@/assets/img/icon32.png');
	}

	.activity-item-cell-address {
		background-image: url('~@/assets/img/icon33.png');
	}
}
</style>
