<template>
	<div class="study-detail">
		<w-navTab titleText="活动列表" v-if="letterDetail.type == 1"></w-navTab>
		<w-navTab titleText="文章详情" v-if="letterDetail.type == 2"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="list" v-if="letterDetail.type == 1">
			<div class="list-item">
				<activity-item v-for="vo in activityList" :activity="vo" @click="onClickItem"></activity-item>
			</div>
		</div>
		<div class="head" v-if="letterDetail.type == 2">
			<div class="head-nav">
				<div class="head-title">{{ letterDetail.title }}</div>
				<div class="head-bottom">
					<div class="text">
						时间：{{ letterDetail.send_time }}
					</div>
					<div class="text">
						浏览：{{ letterDetail.views }}
					</div>
				</div>
			</div>
			<div class="content" v-html="letterDetail.content"></div>
		</div>
	</div>
</template>
<script>
import Message from '@/api/message';
import ActivityItem from '@/components/ActivityItem';
import LoadingPage from '@/components/LoadingPage';

export default {
	name: 'MessageLetterDetail',
	data() {
		return {
			loadingPage: true,
			letterDetail: '',
			activityList: []
		};
	},
	created() {
		this.onletterDetail();
	},
	methods: {
		onletterDetail() {
			Message.letterDetail({
				letter_id: this.$route.params.id
			}).then(result => {
				this.letterDetail = result.data;
				if (this.letterDetail.type == 1) {
					this.letterList();
				} else {
					this.loadingPage = false;
				}
			}).catch(error => {
				console.log(error);
			});
		},
		letterList() {
			Message.letterActivities({
				letter_id: this.$route.params.id
			}).then(result => {
				this.activityList = result.data;
				this.loadingPage = false;
			}).catch(error => {
				console.log(error);
			});
		},
		onClickItem(itemData) {
			this.$router.push({
				name: 'ActivityDetail',
				params: {
					activityId: itemData.id
				}
			})
		},
	},
	components: {
		ActivityItem,
		LoadingPage
	}
};
</script>
<style scoped lang="less">
.study-detail {
	.head {
		padding-top: 20px;
		background-color: #fff;

		.head-nav {
			margin: 20px;
			margin-top: 0;
			border-bottom: 1px solid #ddd;
			padding-bottom: 2px;

			.head-title {
				font-size: 18px;
				line-height: 26px;
				color: #222;
				margin-bottom: 5px;
				font-weight: bold;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}

			.head-bottom {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.head-bottom-item {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.text {
						font-size: 12px;
						line-height: 26px;
						color: #777;
						margin-right: 15px;
					}

					.text:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.content {
			margin: 0 20px;
			padding-bottom: 15px;
			font-size: 16px;
			line-height: 26px;
			color: #222;
		}
	}

	.list {
		padding: 10px;

		.list-item {
			border-radius: 8px;
			overflow: hidden;
		}
	}
}
</style>
